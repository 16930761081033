import React, { FC, ReactNode, isValidElement, ElementType, forwardRef } from 'react'

import classNames from 'classnames'

import { WarningSVG } from 'assets/common'
import TooltipContainer from 'components/TooltipContainer'
import Avatar from 'components/atoms/Avatar'
import { checkExistsFunction } from 'helpers'
import { EmployeeStatus } from 'types/data.types'

import LoadingEmployeeItem from './LoadingEmployeeItem'
import { EmployeeItemTitle, EmployeeItemRoot, EmployeeItemDepartment, EmployeeItemInfo, IconWrap } from './styles'

type Props = {
    emplPhoto: string | null
    emplName: string
    emplDepartment?: string
    customAvatar?: ReactNode
    avatarSize?: number
    avatarFontSize?: number
    onSelect?: () => void
    disabled?: boolean
    variant?: ElementType
    employeeStatus?: EmployeeStatus
    forwardedRef?: React.Ref<HTMLLIElement> | null
    loading?: boolean
    showEmployeeInfo?: boolean
    isSelected?: boolean
    children?: React.ReactNode
    renderEmployeeIcon?: () => React.ReactNode
    customName?: string
}

const EmployeeItem: FC<Props> = ({
    emplName,
    emplPhoto,
    emplDepartment,
    customAvatar,
    avatarSize = 32,
    avatarFontSize,
    onSelect,
    children,
    disabled,
    variant,
    employeeStatus,
    forwardedRef,
    loading,
    showEmployeeInfo = true,
    isSelected,
    renderEmployeeIcon,
    customName,
}) => {
    if (loading) {
        return <LoadingEmployeeItem size={avatarSize} />
    }

    return (
        <EmployeeItemRoot
            onClick={() => !disabled && checkExistsFunction(onSelect)()}
            disabled={disabled}
            className={classNames('employee-item text-cut', { selected: isSelected })}
            as={variant}
            ref={forwardedRef}
        >
            {isValidElement(customAvatar) ? (
                customAvatar
            ) : (
                <>
                    <Avatar imgSize={avatarSize} fontSize={avatarFontSize} src={emplPhoto} name={emplName} />
                    {renderEmployeeIcon ? (
                        renderEmployeeIcon()
                    ) : (
                        <IconWrap>
                            {employeeStatus && employeeStatus !== EmployeeStatus.REGISTRED && (
                                <TooltipContainer
                                    title="User not registered, won't get notifications"
                                    placement="right"
                                    offset="0px, 8px"
                                >
                                    <WarningSVG />
                                </TooltipContainer>
                            )}
                        </IconWrap>
                    )}
                </>
            )}
            {showEmployeeInfo && (
                <EmployeeItemInfo>
                    <EmployeeItemTitle className="text-cut" isSelected={!!isSelected}>
                        {customName || emplName}
                    </EmployeeItemTitle>
                    {emplDepartment && <EmployeeItemDepartment>{emplDepartment}</EmployeeItemDepartment>}
                </EmployeeItemInfo>
            )}
            {children}
        </EmployeeItemRoot>
    )
}

export const EmployeeItemWithForwardedRef = forwardRef<HTMLLIElement, Props>((props, ref) => {
    return <EmployeeItem {...props} forwardedRef={ref} />
})

export default EmployeeItem
